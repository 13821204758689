<template>
    <div class="wrap">
        <!-- 헤더 -->
        <main-header></main-header>

        <div class="wrap">
        <div class="container">
            <h1 class="sub-title">견적서 리스트</h1>
            <!-- <a class="gradiation-button button-size-big right"type="button" href="/saler/order/write" name="button">견적서 작성하기</a> -->
        </div>
        <div class="container container-contents pt100">
            <div v-for="order in orders" class="" >
                <a class="quot-item" href="/buyer/order/view/">
                <div class="quote-item-left" >
                    <h5 class="quote-title">{{order.name}}<span class="quote-date"> {{order.date}}</span></h5>
                    <p class="quote-customer">유통업체 : 유통업체명 (02-1234-1234)</p>
                    <h4 class="quote-price">{{order.price}}원</h4>
                </div>
                <div class="quote-item-right">
                    <h5 class="quote-stat blue-button button-size-mid">{{order.state}}</h5>
                </div>
                </a>
            </div>
        </div>
    </div>

        <!-- 푸터 -->
        <main-footer></main-footer>
    </div>
</template>

<script>
import BuyerHeader from '../../../components/client/inc/buyer-header.vue';
import MainFooter from '../../../components/client/inc/main-footer.vue';

export default{
    components: {
        BuyerHeader,
        MainFooter
    },
    data: () => {
        return {
            orders: [
                { _id: 1, name: "핼로감자탕 및 고기", price: 25000, state:"견적확인중", date:"2020-08-12 15:12:12", person:"김성근", company:"핼로비젼" },
                { _id: 2, name: "조림용 고구마 10kg", price: 25000,state:"주문취소", date:"2020-08-12 15:12:12", person:"김성근", company:"핼로비젼"},
                { _id: 3, name: "조림용 당근 10kg", price: 25000,state:"결제완료", date:"2020-08-12 15:12:12", person:"김성근", company:"핼로비젼"},
                { _id: 3, name: "조림용 당근 10kg", price: 25000,state:"배송완료", date:"2020-08-12 15:12:12", person:"김성근", company:"핼로비젼"},
            ],
        }
    },

    created() {
    },
    mounted() {
    },
    methods : {

    }
}
</script>
