<template>
    <div class="wrap">
        <!-- 헤더 -->
        <main-header></main-header>
        <div class="wrap">
            <div class="container">
                <h1 class="sub-title">견적서 작성하기</h1>
            </div>
            <div class="container container-contents">
                <div class="quote-write-delivery">
                    <h2 class="quote-write-title">배송지 정보</h2>
                    <button class="gradiation-button button-size-mid right" type="button" name="button" @click="onPopup">기존고객선택</button>
                    <div class="quote-write-delivery-input">
                        <table>
                            <tr>
                                <td>수령인</td>
                                <td><input v-model="receiver.name" type="text"></input></td>
                            </tr>
                            <tr>
                                <td>업체명</td>
                                <td><input v-model="receiver.company" type="text"></input></td>
                            </tr>
                            <tr>
                                <td>회사전화번호</td>
                                <td><input v-model="receiver.companynumber" type="text"></input></td>
                            </tr>
                            <tr>
                                <td>휴대폰</td>
                                <td><input v-model="receiver.phone" type="text"></input></td>
                            </tr>
                            <tr>
                                <td>우편번호</td>
                                <td><input v-model="receiver.postcode" type="text"></input><button class="button-blue">배송지검색</button></td>
                            </tr>
                            <tr>
                                <td>주소</td>
                                <td><input v-model="receiver.address1" type="text"></input></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="quoet-write-prdlist quote-write-delivery-input">
                    <h2 class="quote-write-title">견적내용 입력</h2>
                    <table class="quote-name">
                        <tr>
                            <td>견적명</td>
                            <td><input type="text"></input></td>
                        </tr>
                    </table>
                    <table class="quote-product">
                            <tr>
                                <th>품명</th>
                                <th>수량</th>
                                <th>단가</th>
                                <th>공급가</th>
                            </tr>
                            <tr class="quote-product" v-for="basket in baskets">
                                <td style="text-align:left;height:45px;border-right:1px solid rgba(0,0,0,0.1);border-left:1px solid rgba(0,0,0,0.1);padding-left:10px;">{{ basket.selected }} {{ basket.name }}</td>
                                <td style="text-align:center;border-right:1px solid rgba(0,0,0,0.1)">{{ basket.amount.format() }}</td>
                                <td style="text-align:center;border-right:1px solid rgba(0,0,0,0.1)">{{ basket.price.format() }}원</td>
                                <td style="text-align:center;border-right:1px solid rgba(0,0,0,0.1)">{{ (basket.amount * basket.price).format() }}원</td>
                            </tr>
                    </table>
                    <button class="button-blue width100 mt50" style="margin-top:5px;height:46px;;margin-bottom:30px"@click="onAddPopup" > 품목 추가하기 </button>
                    <div class="order-total" style="display:flex; vertical-align:bottom;align-items:flex-end;">
                        <h3>합계 &nbsp;&nbsp;</h3>
                        <p>&nbsp;VAT포함가입니다</p>
                        <p style="float:right;right:0px;position:absolute;font-size:20px;font-weight:bold;color:#eb2727;">상품합계(<span>{{ totalPrice.format() }}원</span>) </p>
                    </div>
                </div>
                <div class="button container button-container">
                    <a href="" class="button-grey" href="/saler/order/list">취소하기</a>
                    <a href="" class="button-blue" href="/saler/order/complete">견적서 발송하기</a>
                </div>
            </div>
        </div>
        <!-- 푸터 -->
        <main-footer></main-footer>
        <receiver-popup ref="receiver-popup" @change="onChangeReceiver"></receiver-popup>
        <add-product-popup ref="add-product-popup" @change="onChangeBaskets"></add-product-popup>


    </div>
</template>

<script>
import MainHeader from '../../../components/client/inc/main-header.vue';
import MainFooter from '../../../components/client/inc/main-footer.vue';
import ReceiverPopup from '../../../components/client/saler/receiver-popup.vue';
import AddProductPopup from '../../../components/client/saler/add-product-popup.vue';

export default{
    components: {
        MainHeader,
        MainFooter,
        ReceiverPopup,
        AddProductPopup,
    },
    data: () => {
        return {
            receiver: {},
            baskets: []
        }
    },
    created() {
    },
    mounted() {
        // this.$refs['add-product-popup'].$on('change', () => {
            // console.log("mounted");
        // });
    },
    methods : {
        onPopup(){
            this.$refs['receiver-popup'].open();
        },
        onAddPopup(){
            this.$refs['add-product-popup'].baskets = JSON.parse(JSON.stringify(this.baskets));
            this.$refs['add-product-popup'].open();
        },
        onChangeReceiver(receiver){
            this.receiver = receiver;
        },
        onChangeBaskets(baskets){
            // console.log("onChangeBaskets");
            console.log(baskets);
            this.baskets = baskets;
        }
    },
    computed: {
        totalPrice(){
            var totalPrice = 0;
            for(var i in this.baskets){
                totalPrice += (this.baskets[i].amount * this.baskets[i].price);
            }
            return totalPrice;
        },
    }
}
</script>
