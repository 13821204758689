<template>
    <div v-if="show" class="receiver-popup">
        <div class="popup-wrapper">
            <img src="/images/icon/close.png" class="popup-close-button" @click="close"></button>
            <div class="popup">
                <div v-for="receiver in receivers" class="pre-receiver-item" @click="input(receiver)">
                    <label class="receiver-radio-label" v-bind:for="receiver.name">
                        <input type="radio" class="receiver-radio" v-bind:id="receiver.name" name="receiver">
                        <div class="pre-receiver-item-left">
                            <h5>{{receiver.name}} <span>({{receiver.phone}})</span></h5>
                            <table>
                                <tr>
                                    <td>업체명 :</td>
                                    <td>{{receiver.company}}</td>
                                </tr>
                                <tr>
                                    <td>업체번호 :</td>
                                    <td>{{receiver.companynumber}}</td>
                                </tr>
                                <tr>
                                    <td>업체주소 :</td>
                                    <td>{{receiver.address1}} {{receiver.address2}}</td>
                                </tr>
                            </table>
                        </div>
                            <!-- <div class="pre-receiver-item-right">
                                <button>선택</button>
                            </div> -->
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data: () => {
        return {

            show: false,
             receivers : [
                 {_id:1,name:"김한솔",company:"핼로비젼",phone:"010-1234-1234",companynumber:"02-1234-1234",postcode:12321,address1:"서울시 구로구 디지털로 29길 39에이스테크노타워",address2:"3차 707호"},
                 {_id:2,name:"김치국",company:"유투브",phone:"010-1234-1234",companynumber:"02-1234-1234",postcode:12321,address1:"서울시 구로구 디지털로 29길 39에이스테크노타워",address2:"3차 707호"},
                 {_id:3,name:"한명섭",company:"서울시",phone:"010-1234-1234",companynumber:"02-1234-1234",postcode:12321,address1:"서울시 구로구 디지털로 29길 39에이스테크노타워",address2:"3차 707호"},
                 {_id:4,name:"부지부",company:"구로구",phone:"010-1234-1234",companynumber:"02-1234-1234",postcode:12321,address1:"서울시 구로구 디지털로 29길 39에이스테크노타워",address2:"3차 707호"},
                 {_id:5,name:"강은미",company:"엘지전자",phone:"010-1234-1234",companynumber:"02-1234-1234",postcode:12321,address1:"서울시 구로구 디지털로 29길 39에이스테크노타워",address2:"3차 707호"},
                 {_id:6,name:"김찬영",company:"삼성전자",phone:"010-1234-1234",companynumber:"02-1234-1234",postcode:12321,address1:"서울시 구로구 디지털로 29길 39에이스테크노타워",address2:"3차 707호"},

             ]
        }
    },
    methods: {
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        input(receiver){
            this.$emit('change', receiver);
            this.close();
        }
    }
}
</script>

<style>
.receiver-popup { background: rgba(0,0,0,0.3); position: fixed; width: 100%; height: 100%; top: 0; bottom: 0; left: 0; right: 0;}
.receiver-popup .popup-wrapper { /* background: white; */ width: 400px; height: 550px; position: fixed; top: 0; bottom: 0; left: 0; right: 0; margin: auto;}
.popup-close-button{ position: relative; top: -10px; bottom: 0; left: 0; right: 0; padding: 0px 0px; float:right; color:white; font-size:20px;}
.popup{ background: white; width: 400px; height: 550px; position: relative; top: 0; bottom: 0; left: 0; right: 0; margin: auto; overflow : scroll;padding:15px 15px 5px 15px; border-radius:4px;box-shadow:0px 0px 15px rgba(0,0,0,0.3)}
.pre-receiver-item{display:flex; flex-wrap:wrap;border-radius: 5px; border:1px solid rgba(0,0,0,0.1); padding:10px;box-shadow:0px 0px 3px rgba(0,0,0,0.1); margin-bottom:10px; }
.pre-receiver-item:hover{background-color: rgba(0,0,0,0.05)}
.pre-receiver-item-left{ flex-grow:1;max-width:280px;}
.pre-receiver-item-left h5 {font-size:18px;font-weight:bold;}
.pre-receiver-item-left table tr td{font-size:14px; color:rgba(0,0,0,0.5)}
.pre-receiver-item-left table tr td:nth-child(1){font-size:14px; font-weight:bold;width:70px;vertical-align: top; }
.pre-receiver-item-left h5 span {font-weight:200;}
.pre-receiver-item-left h6 span{font-weight:300;}
.pre-receiver-item-right {display:flex; align-items:center;}
.pre-receiver-item-right button{height:38px;;background: rgb(23,161,242); background: linear-gradient(90deg, rgba(23,161,242,1) 0%, rgba(27,104,255,1) 100%); padding:4px 8px; color:white; font-size:16px;border-radius:6px;}
.pre-receiver-item:hover{background:linear-gradient(90deg, #17a1f2 0%, #1b76ff 100%); cursor: pointer; color:white; }
.pre-receiver-item:hover table tr td{color:white; cursor: pointer;}
.pre-receiver-item:hover h5{cursor:pointer;}
.receiver-radio[type=radio]:checked + label{
    background-color: black;
}
.popup-close-button{cursor:pointer;}
</style>
