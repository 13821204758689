var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap"
  }, [_c('buyer-header'), _vm._m(0), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h3', {
    staticClass: "title"
  }), _c('div', {}, [_c('h5', [_vm._v("배송정보")]), _c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("기존배송지에서 선택")])]), _c('table', [_c('tr', [_c('td', [_c('label', [_vm._v("배송지명칭")])]), _c('td', [_c('input', {
    attrs: {
      "type": "text"
    }
  })])]), _c('tr', [_c('td', [_c('label', [_vm._v("수령인")])]), _c('td', [_c('input', {
    attrs: {
      "type": "text"
    }
  })])]), _c('tr', [_c('td', [_c('label', [_vm._v("주소")])]), _c('td', [_c('input', {
    attrs: {
      "type": "text"
    }
  }), _c('button', [_vm._v("우편번호검색")]), _c('br'), _c('input', {
    attrs: {
      "type": "text"
    }
  })])]), _c('tr', [_c('td', [_c('label', [_vm._v("연락처")])]), _c('td', [_c('input', {
    attrs: {
      "type": "text"
    }
  })])]), _c('tr', [_c('td', [_c('label', [_vm._v("요청사항")])]), _c('td', [_c('textarea', {
    attrs: {
      "type": "text"
    }
  })])]), _c('tr', [_c('td', [_c('label', [_vm._v("세금계산서")])]), _c('td', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "radio",
      "name": "",
      "value": ""
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("미발행")])]), _c('label', {
    staticClass: "radio"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "radio",
      "name": "",
      "value": ""
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("발행")])])])])])])]);

}]

export { render, staticRenderFns }