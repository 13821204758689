<template>
    <div class="wrap">
        <!-- 헤더 -->
        <buyer-header></buyer-header>
        <div class="wrap">
            <div class="container">
                <h1 class="sub-title">견적내용 변경 요청</h1>
                <p class="change-p">견적서 변경 내용을 입력해주세요</p>
                <textarea class="change-textarea" name="name" rows="8" cols="80" placeholder="내용을 입력해주세요"></textarea>
                <a class="gradiation-button button-size-big change-button"type="button" href="/saler/order/write" name="button">변경요청하기</a>

            </div>
        </div>
        <!-- 푸터 -->
        <main-footer></main-footer>

    </div>
</template>

<script>
import BuyerHeader from '../../../components/client/inc/buyer-header.vue';
import MainFooter from '../../../components/client/inc/main-footer.vue';

export default{
    components: {
        BuyerHeader,
        MainFooter
    },
    created() {
    },
    mounted() {
    },
    methods : {
    },
    computed: {
    },
    }

</script>
