var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap"
  }, [_c('main-header'), _c('div', {
    staticClass: "wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "container container-contents"
  }, [_c('div', {
    staticClass: "quote-write-delivery"
  }, [_c('h2', {
    staticClass: "quote-write-title"
  }, [_vm._v("배송지 정보")]), _c('button', {
    staticClass: "gradiation-button button-size-mid right",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.onPopup
    }
  }, [_vm._v("기존고객선택")]), _c('div', {
    staticClass: "quote-write-delivery-input"
  }, [_c('table', [_c('tr', [_c('td', [_vm._v("수령인")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.receiver.name,
      expression: "receiver.name"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.receiver.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.receiver, "name", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_vm._v("업체명")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.receiver.company,
      expression: "receiver.company"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.receiver.company
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.receiver, "company", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_vm._v("회사전화번호")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.receiver.companynumber,
      expression: "receiver.companynumber"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.receiver.companynumber
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.receiver, "companynumber", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_vm._v("휴대폰")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.receiver.phone,
      expression: "receiver.phone"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.receiver.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.receiver, "phone", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_vm._v("우편번호")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.receiver.postcode,
      expression: "receiver.postcode"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.receiver.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.receiver, "postcode", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "button-blue"
  }, [_vm._v("배송지검색")])])]), _c('tr', [_c('td', [_vm._v("주소")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.receiver.address1,
      expression: "receiver.address1"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.receiver.address1
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.receiver, "address1", $event.target.value);
      }
    }
  })])])])])]), _c('div', {
    staticClass: "quoet-write-prdlist quote-write-delivery-input"
  }, [_c('h2', {
    staticClass: "quote-write-title"
  }, [_vm._v("견적내용 입력")]), _vm._m(1), _c('table', {
    staticClass: "quote-product"
  }, [_vm._m(2), _vm._l(_vm.baskets, function (basket) {
    return _c('tr', {
      staticClass: "quote-product"
    }, [_c('td', {
      staticStyle: {
        "text-align": "left",
        "height": "45px",
        "border-right": "1px solid rgba(0,0,0,0.1)",
        "border-left": "1px solid rgba(0,0,0,0.1)",
        "padding-left": "10px"
      }
    }, [_vm._v(_vm._s(basket.selected) + " " + _vm._s(basket.name))]), _c('td', {
      staticStyle: {
        "text-align": "center",
        "border-right": "1px solid rgba(0,0,0,0.1)"
      }
    }, [_vm._v(_vm._s(basket.amount.format()))]), _c('td', {
      staticStyle: {
        "text-align": "center",
        "border-right": "1px solid rgba(0,0,0,0.1)"
      }
    }, [_vm._v(_vm._s(basket.price.format()) + "원")]), _c('td', {
      staticStyle: {
        "text-align": "center",
        "border-right": "1px solid rgba(0,0,0,0.1)"
      }
    }, [_vm._v(_vm._s((basket.amount * basket.price).format()) + "원")])]);
  })], 2), _c('button', {
    staticClass: "button-blue width100 mt50",
    staticStyle: {
      "margin-top": "5px",
      "height": "46px",
      "margin-bottom": "30px"
    },
    on: {
      "click": _vm.onAddPopup
    }
  }, [_vm._v(" 품목 추가하기 ")]), _c('div', {
    staticClass: "order-total",
    staticStyle: {
      "display": "flex",
      "vertical-align": "bottom",
      "align-items": "flex-end"
    }
  }, [_c('h3', [_vm._v("합계   ")]), _c('p', [_vm._v(" VAT포함가입니다")]), _c('p', {
    staticStyle: {
      "float": "right",
      "right": "0px",
      "position": "absolute",
      "font-size": "20px",
      "font-weight": "bold",
      "color": "#eb2727"
    }
  }, [_vm._v("상품합계("), _c('span', [_vm._v(_vm._s(_vm.totalPrice.format()) + "원")]), _vm._v(") ")])])]), _vm._m(3)])]), _c('main-footer'), _c('receiver-popup', {
    ref: "receiver-popup",
    on: {
      "change": _vm.onChangeReceiver
    }
  }), _c('add-product-popup', {
    ref: "add-product-popup",
    on: {
      "change": _vm.onChangeBaskets
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('h1', {
    staticClass: "sub-title"
  }, [_vm._v("견적서 작성하기")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "quote-name"
  }, [_c('tr', [_c('td', [_vm._v("견적명")]), _c('td', [_c('input', {
    attrs: {
      "type": "text"
    }
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v("품명")]), _c('th', [_vm._v("수량")]), _c('th', [_vm._v("단가")]), _c('th', [_vm._v("공급가")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "button container button-container"
  }, [_c('a', {
    staticClass: "button-grey",
    attrs: {
      "href": "",
      "href": "/saler/order/list"
    }
  }, [_vm._v("취소하기")]), _c('a', {
    staticClass: "button-blue",
    attrs: {
      "href": "",
      "href": "/saler/order/complete"
    }
  }, [_vm._v("견적서 발송하기")])]);

}]

export { render, staticRenderFns }