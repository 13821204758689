var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap"
  }, [_c('main-header'), _c('div', {
    staticClass: "wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "container container-contents pt100"
  }, _vm._l(_vm.orders, function (order) {
    return _c('div', {}, [_c('a', {
      staticClass: "quot-item",
      attrs: {
        "href": "/buyer/order/view/"
      }
    }, [_c('div', {
      staticClass: "quote-item-left"
    }, [_c('h5', {
      staticClass: "quote-title"
    }, [_vm._v(_vm._s(order.name)), _c('span', {
      staticClass: "quote-date"
    }, [_vm._v(" " + _vm._s(order.date))])]), _c('p', {
      staticClass: "quote-customer"
    }, [_vm._v("유통업체 : 유통업체명 (02-1234-1234)")]), _c('h4', {
      staticClass: "quote-price"
    }, [_vm._v(_vm._s(order.price) + "원")])]), _c('div', {
      staticClass: "quote-item-right"
    }, [_c('h5', {
      staticClass: "quote-stat blue-button button-size-mid"
    }, [_vm._v(_vm._s(order.state))])])])]);
  }), 0)]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('h1', {
    staticClass: "sub-title"
  }, [_vm._v("견적서 리스트")])]);

}]

export { render, staticRenderFns }