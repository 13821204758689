var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap"
  }, [_c('main-header'), _c('div', {
    staticClass: "wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "container container-contents"
  }, [_c('div', {
    staticClass: "quote-write-delivery"
  }, [_c('h2', {
    staticClass: "quote-write-title"
  }, [_vm._v("배송지 정보")]), _c('div', {
    staticClass: "quote-write-delivery-input"
  }, [_c('table', [_c('tr', [_c('td', [_vm._v("수령인")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reciever.name,
      expression: "reciever.name"
    }],
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.reciever.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.reciever, "name", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_vm._v("업체명")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reciever.company,
      expression: "reciever.company"
    }],
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.reciever.company
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.reciever, "company", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_vm._v("회사전화번호")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reciever.companynumber,
      expression: "reciever.companynumber"
    }],
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.reciever.companynumber
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.reciever, "companynumber", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_vm._v("휴대폰")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reciever.phone,
      expression: "reciever.phone"
    }],
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.reciever.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.reciever, "phone", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_vm._v("우편번호")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reciever.postcode,
      expression: "reciever.postcode"
    }],
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.reciever.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.reciever, "postcode", $event.target.value);
      }
    }
  })])]), _c('tr', [_c('td', [_vm._v("주소")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reciever.address1,
      expression: "reciever.address1"
    }],
    staticClass: "input-address",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.reciever.address1
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.reciever, "address1", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reciever.address2,
      expression: "reciever.address2"
    }],
    staticClass: "input-address",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.reciever.address2
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.reciever, "address2", $event.target.value);
      }
    }
  })])])])])]), _c('div', {
    staticClass: "quoet-write-prdlist quote-write-delivery-input"
  }, [_c('h2', {
    staticClass: "quote-write-title"
  }, [_vm._v("견적내용 입력")]), _c('table', {
    staticStyle: {
      "margin-bottom": "30px",
      "margin-top": "30px"
    }
  }, [_c('tr', [_c('td', [_vm._v("견적명")]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.order.name,
      expression: "order.name"
    }],
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.order.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.order, "name", $event.target.value);
      }
    }
  })])])]), _c('table', [_vm._m(1), _vm._l(_vm.order.products, function (product) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(product.name) + " " + _vm._s(product.unit))]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v(_vm._s(product.amount))]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v(_vm._s(product.price))]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v(_vm._s(product.totalprice) + "원")])]);
  })], 2), _c('div', {
    staticClass: "order-total"
  }, [_c('h3', [_vm._v("합계")]), _c('p', [_vm._v("VAT포함가입니다")]), _c('p', {
    staticClass: "totals"
  }, [_vm._v("상품합계("), _c('span', [_vm._v(_vm._s(_vm.order.totalProductPrice.format()) + "원")]), _vm._v(")")])]), _vm._m(2)])])]), _c('main-footer'), _c('add-product-popup', {
    ref: "add-product-popup",
    on: {
      "change": _vm.onChangeBaskets
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('h1', {
    staticClass: "sub-title"
  }, [_vm._v("견적서 확인")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v("품명")]), _c('th', [_vm._v("수량")]), _c('th', [_vm._v("단가")]), _c('th', [_vm._v("공급가")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order-state quote-write-delivery-input mt50"
  }, [_c('h2', {
    staticClass: "quote-write-title mt50"
  }, [_vm._v("주문내역")]), _c('table', {
    staticClass: "order-detail-table"
  }, [_c('tr', [_c('td', [_vm._v("주문번호")]), _c('td')]), _c('tr', [_c('td', [_vm._v("주문일자")]), _c('td')]), _c('tr', [_c('td', [_vm._v("결제방법")]), _c('td')]), _c('tr', [_c('td', [_vm._v("택배사")]), _c('td', [_c('select', {
    attrs: {
      "name": "",
      "disabled": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("우체국택배")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("한진택배")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("CJ대한통운")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("대신택배")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("로젠택배")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("현대택배")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("KG로지스")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("경동택배")])])])]), _c('tr', [_c('td', [_vm._v("운송장번호")]), _c('td', [_c('input', {
    attrs: {
      "type": "text",
      "disabled": ""
    }
  })])]), _c('tr', [_c('td', [_vm._v("발송일자")]), _c('td')]), _c('tr', [_c('td', [_vm._v("처리상태")]), _c('td', [_c('select', {
    attrs: {
      "name": "",
      "disabled": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("견적서전달완료")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("결제완료")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("주문변경요청")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("주문변경완료")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("취소")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("배송완료")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("정산완료")])])])])]), _c('div', {
    staticClass: "container center",
    staticStyle: {
      "margin-top": "50px"
    }
  }, [_c('a', {
    staticClass: "button-blue mt50 center",
    attrs: {
      "type": "button",
      "name": "button",
      "href": "/buyer/order/complete"
    }
  }, [_vm._v("결제하기")])])]);

}]

export { render, staticRenderFns }