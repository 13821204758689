var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap"
  }, [_c('buyer-header'), _vm._m(0), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h1', {
    staticClass: "sub-title"
  }, [_vm._v("견적내용 변경 요청")]), _c('p', {
    staticClass: "change-p"
  }, [_vm._v("견적서 변경 내용을 입력해주세요")]), _c('textarea', {
    staticClass: "change-textarea",
    attrs: {
      "name": "name",
      "rows": "8",
      "cols": "80",
      "placeholder": "내용을 입력해주세요"
    }
  }), _c('a', {
    staticClass: "gradiation-button button-size-big change-button",
    attrs: {
      "type": "button",
      "href": "/saler/order/write",
      "name": "button"
    }
  }, [_vm._v("변경요청하기")])])]);

}]

export { render, staticRenderFns }