<template>
    <div class="wrap">
        <!-- 헤더 -->
        <main-header></main-header>
        <div class="wrap">
            <div class="container">
                <h1 class="sub-title">견적서 확인</h1>
                <!-- <button class="gradiation-button button-size-big right"type="button" name="button">견적서 작성하기</button> -->
            </div>
            <div class="container container-contents">
                <div class="quote-write-delivery">
                    <h2 class="quote-write-title">배송지 정보</h2>
                    <!-- <button class="gradiation-button button-size-mid right" type="button" name="button" @click="onPopup">기존고객선택</button> -->
                    <div class="quote-write-delivery-input">
                        <table>
                            <tr>
                                <td>수령인</td>
                                <td><input type="text" v-model="reciever.name"></input> </td>
                            </tr>
                            <tr>
                                <td>업체명</td>
                                <td><input type="text" v-model="reciever.company"></input></td>
                            </tr>
                            <tr>
                                <td>회사전화번호</td>
                                <td><input type="text" v-model="reciever.companynumber"></input></td>
                            </tr>
                            <tr>
                                <td>휴대폰</td>
                                <td><input type="text" v-model="reciever.phone"></input></td>
                            </tr>
                            <tr>
                                <td>우편번호</td>
                                 <td><input type="text" v-model="reciever.postcode"></input> <!-- <button>배송지검색</button>--> </td>
                            </tr>
                            <tr>
                                <td>주소</td>
                                <td><input type="text" class="input-address" v-model="reciever.address1"><input type="text" class="input-address"v-model="reciever.address2"></td>


                            </tr>
                        </table>
                    </div>
                </div>
                <div class="quoet-write-prdlist quote-write-delivery-input">
                    <h2 class="quote-write-title">견적내용 입력</h2>
                    <table style="margin-bottom:30px;margin-top:30px;">
                        <tr >
                            <td>견적명</td>
                            <td><input type="text" v-model="order.name"></input></td>
                        </tr>
                    </table>
                    <table>
                            <tr>
                                <th>품명</th>
                                <th>수량</th>
                                <th>단가</th>
                                <th>공급가</th>
                            </tr>
                            <tr v-for="product in order.products" >
                                <!-- <td>{{ product._id }} </td> -->
                                <td>{{ product.name }} {{ product.unit }}</td>
                                <td style="text-align:center;">{{ product.amount }}</td>
                                <td style="text-align:center;">{{ product.price }}</td>
                                <td style="text-align:center;">{{ product.totalprice }}원</td>

                            </tr>
                    </table>
                    <button class="button-blue width100 mt50" style="margin-top:5px;height:46px;;margin-bottom:30px"@click="onAddPopup" > 품목 추가하기 </button>
                     <div class="order-total">
                        <h3>합계</h3>
                        <p>VAT포함가입니다</p>
                        <p class="totals">상품합계(<span>{{ order.totalProductPrice.format() }}원</span>)</p>

                        <!-- <p>상품합계(<span>{{ order.totalProductPrice.format() }}원</span>) + 배송비(<span>{{ order.deliveryFee.format() }}원</span>) = <span>{{order.totalOrderPrice}}원</span></p> -->
                    </div>
                    <div class="order-state quote-write-delivery-input mt50">
                        <h2 class="quote-write-title mt50">주문내역</h2>

                        <table class="order-detail-table">
                            <tr>
                                <td>주문번호</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>주문일자</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>결제방법</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>택배사</td>
                                <td>
                                    <select class="" name="">
                                        <option value="">우체국택배</option>
                                        <option value="">한진택배</option>
                                        <option value="">CJ대한통운</option>
                                        <option value="">대신택배</option>
                                        <option value="">로젠택배</option>
                                        <option value="">현대택배</option>
                                        <option value="">KG로지스</option>
                                        <option value="">경동택배</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>운송장번호</td>
                                <td><input type="text"></td>
                            </tr>
                            <tr>
                                <td>발송일자</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>처리상태</td>
                                <td>
                                    <select class="" name="">
                                        <option value="">견적확인중</option>
                                        <option value="">결제완료</option>
                                        <option value="">주문변경요청</option>
                                        <option value="">주문변경완료</option>
                                        <option value="">취소</option>
                                        <option value="">배송완료</option>
                                        <option value="">정산완료</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <div class="container center">
                            <button type="button"  class="button-blue  mt50 center"name="button">저장하기</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="">
            결제하기
        </div> -->
        <!-- 푸터 -->
        <main-footer></main-footer>
        <!-- <receiver-popup ref="receiver-popup"></receiver-popup> -->
        <add-product-popup ref="add-product-popup" @change="onChangeBaskets"></add-product-popup>


    </div>
</template>

<script>
import MainHeader from '../../../components/client/inc/main-header.vue';
import MainFooter from '../../../components/client/inc/main-footer.vue';
import ReceiverPopup from '../../../components/client/saler/receiver-popup.vue';
import AddProductPopup from '../../../components/client/saler/add-product-popup.vue';

export default{
    components: {
        MainHeader,
        MainFooter,
        ReceiverPopup,
        AddProductPopup,

    },
    data: () => {
        return {

            baskets: [],

            reciever:
                {
                    _id : 1,
                    name : "김한솔",
                    company : "핼로비젼",
                    phone : "010-1234-1234",
                    companynumber : "02-1234-1234",
                    postcode : 12321,
                    address1 : "서울시 구로구 디지털로 29길 39에이스테크노타워 ",
                    address2 : "3차 707호"

                },
            order: {
                    _id : 1,
                    name : "고구마 3kg 외 야채 500g",
                    products : [
                        {
                            _id: 1 ,name:"고구마", unit:"1kg", amount:3, price:12000, totalprice:36000,
                        },
                        {
                            _id: 2 ,name:"종합야채", unit:"500g", amount:1, price:8000, totalprice:8000
                        }
                    ],
                    detail : "양념감자용 고구마와 야채 추가로 대각선으로 으슷썰기 후 전달 필요",
                    totalProductPrice : 44000,
                    deliveryFee : 4000,
                    totalOrderPrice : 48000
            }

        }
    },
    created() {
    },
    mounted() {
    },
    methods : {
        onPopup(){
            this.$refs['receiver-popup'].open();
        },
        onAddPopup(){
            this.$refs['add-product-popup'].baskets = JSON.parse(JSON.stringify(this.baskets));
            this.$refs['add-product-popup'].open();
        },
        onChangeBaskets(baskets){
            // console.log("onChangeBaskets");
            console.log(baskets);
            this.baskets = baskets;
        }

    },
    computed: {
        totalPrice(){
            var totalPrice = 0;
            for(var i in this.baskets){
                totalPrice += (this.baskets[i].amount * this.baskets[i].price);
            }
            return totalPrice;
        },
    }
}
</script>
