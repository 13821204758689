var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('div', {
    staticClass: "receiver-popup"
  }, [_c('div', {
    staticClass: "popup-wrapper"
  }, [_c('img', {
    staticClass: "popup-close-button",
    attrs: {
      "src": "/images/icon/close.png"
    },
    on: {
      "click": _vm.close
    }
  }), _c('div', {
    staticClass: "popup"
  }, _vm._l(_vm.receivers, function (receiver) {
    return _c('div', {
      staticClass: "pre-receiver-item",
      on: {
        "click": function ($event) {
          return _vm.input(receiver);
        }
      }
    }, [_c('label', {
      staticClass: "receiver-radio-label",
      attrs: {
        "for": receiver.name
      }
    }, [_c('input', {
      staticClass: "receiver-radio",
      attrs: {
        "type": "radio",
        "id": receiver.name,
        "name": "receiver"
      }
    }), _c('div', {
      staticClass: "pre-receiver-item-left"
    }, [_c('h5', [_vm._v(_vm._s(receiver.name) + " "), _c('span', [_vm._v("(" + _vm._s(receiver.phone) + ")")])]), _c('table', [_c('tr', [_c('td', [_vm._v("업체명 :")]), _c('td', [_vm._v(_vm._s(receiver.company))])]), _c('tr', [_c('td', [_vm._v("업체번호 :")]), _c('td', [_vm._v(_vm._s(receiver.companynumber))])]), _c('tr', [_c('td', [_vm._v("업체주소 :")]), _c('td', [_vm._v(_vm._s(receiver.address1) + " " + _vm._s(receiver.address2))])])])])])]);
  }), 0)])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }