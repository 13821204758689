<template>
    <div class="wrap">
        <!-- 헤더 -->
        <Main-header></Main-header>
        <div class="wrap complete-wrap">
            <h3>견적서가 발송되었습니다.</h3>
             <a class="button-blue complete-button" href="/saler/order/list">확인</a>

        </div>
        <!-- 푸터 -->
        <main-footer></main-footer>

    </div>
</template>

<script>
import MainHeader from '../../../components/client/inc/main-header.vue';
import MainFooter from '../../../components/client/inc/main-footer.vue';

export default{
    components: {
        MainHeader,
        MainFooter
    },
    created() {
    },
    mounted() {
    },
    methods : {
    },
    computed: {
    },
    }

</script>
