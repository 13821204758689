<template>
    <div class="wrap">
        <!-- 헤더 -->
        <buyer-header></buyer-header>
        <div class="wrap complete-wrap">
            <h3>결제가 완료되었습니다.</h3>
            <h5>내 결제 리스트 확인하기</h5>
             <a class="button-blue complete-button" href="/buyer/order/list">확인</a>

        </div>
        <!-- 푸터 -->
        <main-footer></main-footer>

    </div>
</template>

<script>
import BuyerHeader from '../../../components/client/inc/buyer-header.vue';
import MainFooter from '../../../components/client/inc/main-footer.vue';

export default{
    components: {
        BuyerHeader,
        MainFooter
    },
    created() {
    },
    mounted() {
    },
    methods : {
    },
    computed: {
    },
    }

</script>
