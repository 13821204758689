<template>
    <header class="header">
        <div class="header__inner">
            <div class="header__middle">
                <div class="header__row">
                    <div class="header__row-inner">

                        <div class="header-contents">
                            <div class="header-logo"><h1><a href="/" title="홈으로 이동"><span class="blind">식자재플랫폼</span></a></h1></div>
                            <div class="right-menu">
                                <ul>
                                    <!-- <li><a class="gnb-text" href="/join"><span>유통사 회원가입</span></a></li>
                                    <li><a class="gnb-button" href="/login"><span>유통사 로그인</span></a></li> -->
                                    <!-- <li><a href="/center/faq">고객센터</a></li> -->
                                    <!-- <li><a class="link" href="/shop/mypet"><i class="icon-footprint"></i>추천 MY PET</a></li>
                                    <li><a class="link" href="/mypage/order-list"><i class="icon-user"></i>마이페이지</a></li>
                                    <li><a class="link" href="/mypage/cart"><em class="badge">2</em><i class="icon-cart"></i>장바구니</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default{
    components: {
    },
    created() {
    },
    mounted() {
        this.header();
    },
    methods : {
        header : function() {
            window.addEventListener('load', function(){
            }, false);
        }
    }
}
</script>
