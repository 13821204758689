var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showProductAdd ? _c('div', {
    staticClass: "receiver-popup"
  }, [_c('div', {
    staticClass: "popup-wrapper"
  }, [_c('img', {
    staticClass: "popup-close-button",
    attrs: {
      "src": "/images/icon/close.png"
    },
    on: {
      "click": _vm.close
    }
  }), _c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "popup-container"
  }, _vm._l(_vm.products, function (product) {
    return _c('div', {
      staticClass: "product-item"
    }, [_c('label', [_c('div', {
      staticClass: "product-item-left"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.basket(product).checked,
        expression: "basket(product).checked"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": true,
        "checked": Array.isArray(_vm.basket(product).checked) ? _vm._i(_vm.basket(product).checked, true) > -1 : _vm.basket(product).checked
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.basket(product).checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = true,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.basket(product), "checked", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.basket(product), "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.basket(product), "checked", $$c);
          }
        }
      }
    }), _c('h5', {
      staticClass: "item-title"
    }, [_vm._v(_vm._s(product.name) + " "), _c('span', {
      staticClass: "item-unit"
    })]), _c('h5', {
      staticClass: "price"
    }, [_vm._v(_vm._s(product.price) + "원")]), _c('div', {
      staticClass: "count"
    })])]), _c('div', {
      staticClass: "product-item-right"
    }, [_c('div', {
      staticClass: "minus count-button",
      on: {
        "click": function ($event) {
          _vm.decAmount(_vm.basket(product));
        }
      }
    }, [_vm._v(" - ")]), _c('div', {
      staticClass: "num-wrap"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.basket(product).amount,
        expression: "basket(product).amount"
      }],
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": _vm.basket(product).amount
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.basket(product), "amount", $event.target.value);
        }
      }
    })]), _c('div', {
      staticClass: "plus count-button",
      on: {
        "click": function ($event) {
          _vm.incAmount(_vm.basket(product));
        }
      }
    }, [_vm._v(" + ")])])]);
  }), 0), _c('button', {
    staticClass: "button primary-color button-blue width100 add-product-button",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.input
    }
  }, [_vm._v(" 선택상품 추가하기 ")])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }