<template>
    <div class="wrap">
        <!-- 헤더 -->
        <buyer-header></buyer-header>

        <!-- 메인 -->
        <div class="wrap">
            <div class="container">
                <h3 class="title"></h3>
                <div class="">
                    <h5>배송정보</h5>
                    <a href="">기존배송지에서 선택</a>
                </div>
                <table>
                    <tr>
                        <td><label>배송지명칭</label></td>
                        <td><input type="text"></td>
                    </tr>
                    <tr>
                        <td><label>수령인</label></td>
                        <td><input type="text"></td>
                    </tr>
                    <tr>
                        <td><label>주소</label></td>
                        <td><input type="text"><button>우편번호검색</button><br>
                            <input type="text">
                        </td>
                    </tr>
                    <tr>
                        <td><label>연락처</label></td>
                        <td><input type="text"></td>
                    </tr>
                    <tr>
                        <td><label>요청사항</label></td>
                        <td><textarea type="text"></textarea></td>
                    </tr>
                    <tr>
                        <td><label>세금계산서</label></td>
                        <td>
                            <label class="radio">
                            <input class="input" type="radio" name="" value="">
                            <i class="icon"></i>
                            <span class="text">미발행</span>
                            </label>
                            <label class="radio">
                            <input class="input" type="radio" name="" value="">
                            <i class="icon"></i>
                            <span class="text">발행</span>
                            </label>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <!-- 푸터 -->
        <main-footer></main-footer>
    </div>
</template>

<script>
import BuyerHeader from '../../../components/client/inc/buyer-header.vue';
import MainFooter from '../../../components/client/inc/main-footer.vue';

export default{
    components: {
        BuyerHeader,
        MainFooter
    },
    created() {
    },
    mounted() {
    },
    methods : {
    }
}
</script>
