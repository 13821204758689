var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap"
  }, [_c('Main-header'), _vm._m(0), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap complete-wrap"
  }, [_c('h3', [_vm._v("견적서가 발송되었습니다.")]), _c('a', {
    staticClass: "button-blue complete-button",
    attrs: {
      "href": "/saler/order/list"
    }
  }, [_vm._v("확인")])]);

}]

export { render, staticRenderFns }