var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header__inner"
  }, [_c('div', {
    staticClass: "header__middle"
  }, [_c('div', {
    staticClass: "header__row"
  }, [_c('div', {
    staticClass: "header__row-inner"
  }, [_c('div', {
    staticClass: "header-contents"
  }, [_c('div', {
    staticClass: "header-logo"
  }, [_c('h1', [_c('a', {
    attrs: {
      "href": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("식자재플랫폼")])])])]), _c('div', {
    staticClass: "right-menu"
  }, [_c('ul')])])])])])])]);

}]

export { render, staticRenderFns }