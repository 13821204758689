<template>
    <div v-if="showProductAdd" class="receiver-popup">
        <div class="popup-wrapper">
            <img src="/images/icon/close.png" class="popup-close-button" @click="close"></button>
            <div class="popup">
                <!-- <button class="button primary-color button-blue right" type="button" name="button">
                    새로운 상품 등록
                </button> -->

                <div class="popup-container">
                    <div v-for="product in products" class="product-item">
                        <label>
                            <div class="product-item-left">
                                <input v-model="basket(product).checked" type="checkbox" :value="true">
                                <h5 class="item-title">{{ product.name }} <span class="item-unit"></span></h5>
                                <h5 class="price">{{ product.price }}원</h5>
                                <div class="count">
                                </div>
                            </div>
                        </label>
                        <div class="product-item-right">
                            <div class="minus count-button" @click="decAmount(basket(product))">
                                -
                            </div>
                            <div  class="num-wrap">
                                <input type="text" v-model="basket(product).amount" >
                            </div>
                            <div class="plus count-button" @click="incAmount(basket(product))">
                                +
                            </div>
                        </div>
                    </div>
                </div>
                <button class="button primary-color button-blue width100 add-product-button" type="button" name="button" @click="input">
                    선택상품 추가하기
                </button>

            </div>

        </div>
    </div>

</template>

<script>
export default{
    props: {
        // baskets: { type: Array, default: [] },
    },
    data: () => {
        return {
            showProductAdd: false,
            prdnum : 12321312421,
            unitCount : '5kg',
            price : 25000,
            count : 0,
            products: [
                { _id: 1, name: "조림용 감자", price: 25000 },
                { _id: 2, name: "조림용 고구마", price: 25000 },
                { _id: 3, name: "조림용 당근", price: 25000 },
                { _id: 4, name: "김치", price: 25000 },
                { _id: 5, name: "장조림", price: 25000 },
                { _id: 6, name: "참치", price: 25000 },
                { _id: 7, name: "참치통조림", price: 25000 },
                { _id: 8, name: "국거리 소고기", price: 25000 },
                { _id: 9, name: "당근", price: 25000 },

            ],
            baskets: []
        }
    },
    // whatch : {
    //     count : () =>  {
    //         return this.count = this.count.replace(/[^0-9]/gi, '');
    //     }
    // },
    methods: {
        open(){
            this.showProductAdd = true;
        },
        close(){
            this.showProductAdd = false;
        },

        input(){
            this.$emit('change', this.baskets.filter((basket) => basket.checked));
            this.close();
        },

        basket(product){
            // function(){
            //     for(var i in this.baskets){
            //         var basket = this.baskets[i];
            //         if(basket._id == product._id) return basket;
            //     }
            //     return null;
            // }

            var basket = this.baskets.find((basket) => basket._id == product._id);
            if(!basket){
                basket = Object.assign({ amount: 0, checked: false }, product);
                // var a = { amount: 1, checked: false };
                // var b = { _id: 1, name: "조림용", price: 10900 };
                // -> var a = { amount: 1, checked: false, _id: 1, name: "조림용", price: 10900 };

                this.$set(this.baskets, this.baskets.length, basket);

                // push -> [a]
                // $set -> [a]
            }
            return basket;
        },
        decAmount(basket){
            if(basket.amount >= 1){
                basket.amount --;
            }else{
                basket.amount;
            }
        },
        incAmount(basket){
            basket.amount ++;
            basket.checked = true;
        },
        addProductToList(){

            this.showProductAdd = false;
        }


    }
}
</script>

<style>
.receiver-popup { background: rgba(0,0,0,0.3); position: fixed; width: 100%; height: 100%; top: 0; bottom: 0; left: 0; right: 0;}
.receiver-popup .popup-wrapper { /* background: white; */ width: 400px; height: 550px; position: fixed; top: 0; bottom: 0; left: 0; right: 0; margin: auto;}
.popup-close-button{ position: relative; top: -10px; bottom: 0; left: 0; right: 0; padding: 0px 0px; float:right; color:white; font-size:20px;}
.popup{ background: white; width: 400px; height: 550px; position: relative; top: 0; bottom: 0; left: 0; right: 0; margin: auto; overflow : scroll;padding:15px 15px 5px 15px; border-radius:4px;box-shadow:0px 0px 15px rgba(0,0,0,0.3)}
.product-item{display:flex; flex-wrap:wrap;border-radius: 5px; border:1px solid rgba(0,0,0,0.1); padding:10px;box-shadow:0px 0px 3px rgba(0,0,0,0.1); margin-bottom:10px; }
.product-item:hover{background-color: rgba(0,0,0,0.05)}
.product-item-left{ flex-grow:1;max-width:280px;}
.product-item-left h5 {font-size:18px;font-weight:bold;}
.product-item-left table tr td{font-size:14px; color:rgba(0,0,0,0.5)}
.product-item-left table tr td:nth-child(1){font-size:14px; font-weight:bold;width:70px;vertical-align: top; }
.product-item-left h5 span {font-weight:200;}
.product-item-left h6 span{font-weight:300;}
.product-item-right {display:flex; align-items:center;}
.product-item-right button{height:38px;;background: rgb(23,161,242); background: linear-gradient(90deg, rgba(23,161,242,1) 0%, rgba(27,104,255,1) 100%); padding:4px 8px; color:white; font-size:16px;border-radius:6px;}
.num-wrap{width:50px;margin:0 4px;}
.num-wrap input{width:50px; padding:0px;height:40px;text-align: center;font-size:18px;color:#f33636;font-weight:bold;}
.count-button{font-size:30px;height:40px;line-height:35px;width:40px;text-align: center;color:white;background-color:rgba(0,0,0,0.2)}
.price{color:#f33636;}
</style>
